import React from "react";
import "./Sidebar.css";

function BackgroundPage(props) {
  return (
    <div>
      <p></p>
    </div>
  );
}

export default BackgroundPage;
