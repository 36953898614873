import React from "react";
import "./QuizSidebar.css";

function QuizBackgroundPage(props) {
  return (
    <div>
      <p></p>
    </div>
  );
}

export default QuizBackgroundPage;
