import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import WelcomeCourseCard from "./WelcomeCourseCard";
import { Link } from "react-router-dom";
import Logout from "./Logout";
import { useHistory } from "react-router-dom";

function WelcomeCourseMenuPage() {
  const [coursesDisplayed, setCoursesDisplayed] = useState([]);
  useEffect(() => {
    axios(`https://hhcourses-backend.vercel.app/coursesData`).then((response) => {
      setCoursesDisplayed(response.data);
      console.log(response.data);
    });
  }, []);
  console.log(coursesDisplayed);

  return (
    <div>
      <div className="courseCardsContainer">
        {coursesDisplayed.map((course, index) => {
          return (
            <>
              {course.courseIsActive && (
                <WelcomeCourseCard course={course}></WelcomeCourseCard>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}

export default WelcomeCourseMenuPage;
